// extracted by mini-css-extract-plugin
export var mainImageAndRightCardContainer = "biochip-module--main-image-and-right-card-container--3w-3-";
export var mainImage = "biochip-module--main-image--oX6cU";
export var rightCard = "biochip-module--right-card--RW8zj";
export var mainEggContainer = "biochip-module--main-egg-container--2hjNH";
export var container = "biochip-module--container--0HFPC";
export var columns1And2LeftColumn = "biochip-module--columns-1-and-2-left-column--0Pw1z";
export var columns2 = "biochip-module--columns-2--VPgCf";
export var table = "biochip-module--table--W8tIt";
export var image3AndSpecificationsCardContainer = "biochip-module--image3-and-specifications-card-container--gdhWh";
export var image3Container = "biochip-module--image-3-container--SEV0s";
export var image3 = "biochip-module--image3--B2zoZ";
export var specificationsCard = "biochip-module--specifications-card--H+v1F";
export var columns3 = "biochip-module--columns-3--w-usg";
export var columns3LeftColumn = "biochip-module--columns-3-left-column--32nA0";
export var columns3RightColumn = "biochip-module--columns-3-right-column--jRA28";
export var diagram = "biochip-module--diagram--+f2tL";