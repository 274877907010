import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import ProductPageContainer from "../../components/common/ProductPageContainer";
import FigureAlt from "../../components/common/FigureAlt";
import Card from "../../components/common/Card";
import Columns from "../../components/common/Columns";
import ShadowEgg from "../../components/common/ShadowEgg";
import ProductSpecificationsCard from "../../components/products/ProductSpecificationsCard";
import ChevronLink from "../../components/common/ChevronLink";

import * as classes from "./biochip.module.scss";

export default function BiochipPage({data}) {

    return (
        <ProductPageContainer className={classes.container}>

            <Helmet>
                <title>Биочип – тест-система для иммуноцитохимического исследования</title>
            </Helmet>

            <h1>Биочип — тест‑система для иммуноцитохи­ми­чес­ко­го исследования</h1>

            <p>Представляет собой комплекс миниатюрных химических реакционных зон, расположенных в определенном порядке и нанесенных на твердую матрицу (стеклянную подложку с размерами по длине и ширине 25,0 × 75,0 мм (± 0,5 мм), толщиной 1 мм). Объем ячейки составляет 0,18 см<sup>3</sup>.</p>
            <div className={classes.mainImageAndRightCardContainer}>
                <FigureAlt className={classes.mainImage}>
                    <StaticImage
                        src="img/biochip-1.jpg"
                        alt="Упаковка для тест-системы для экспресс-диагностики рака «Биочип» под одного пациента"
                        layout="constrained"
                        width={564}
                        height={308} />
                </FigureAlt>
                <Card
                    className={classes.rightCard}
                    use="aside">
                    <h3>Состав набора</h3>
                    <ul>
                        <li>Тест-система</li>
                        <li>Реагент №1 «Первичные антитела»</li>
                        <li>Реагент №2 «Вторичные антитела»</li>
                        <li>Реагент №3 «Разбавитель»</li>
                        <li>Реагент №4 «Диаминобензидин»</li>
                        <li>Реагент №5 «Гематоксилин»</li>
                    </ul>
                </Card>
            </div>
            <ShadowEgg className={classes.mainEggContainer}>
                <StaticImage
                    src="img/biochip-2.jpg"
                    alt="Предметное стекло внутри тест-системы для диагностики злокачественных новообразований «Биочип»"
                    layout="constrained"
                    width={477}
                    height={369} />
            </ShadowEgg>
            <h3>Поверхность разделена на две части:</h3>
            <ul>
                <li>Часть №1 считается рабочей поверхностью, разделенной крышкой и прокладкой на три равные ячейки, предназначена для внесения и фиксации исследуемого биоматериала;</li>
                <li>Часть №2 включает в себя две ячейки: ячейка номер 1 содержит положительный контрольный материал, характеризующийся позитивной экспрессией маркера EpCAM/BerEp4, а ячейка номер 2 содержит отрицательный контрольный материал, характеризующейся негативной экспрессией маркера EpCAM/BerEp4.</li>
            </ul>
            <h3>Исследуемый материал:</h3>
            <p>Клетки, полученные из выпотов в брюшную и плевральную полость.</p>

            <h2>Преимущества диагностики с биочипами в рамках первичного звена</h2>
            <Columns>
                <Columns.Column className={classes.columns1And2LeftColumn}>
                    <h3>Преимущества для пациентов:</h3>
                    <ul>
                        <li>только необходимые для правильного диагноза исследования;</li>
                        <li>весь спектр диагностики в своей поликлинике по м.ж.;</li>
                        <li>своевременное лечение.</li>
                    </ul>
                </Columns.Column>
                <Columns.Column>
                    <h3>Преимущества для ЛПУ:</h3>
                    <ul>
                        <li>повышение потока пациентов — финансовое оздоровление;</li>
                        <li>повышение статуса больницы;</li>
                        <li>выполнение целевых приказов МЗ по срокам и диагностике;</li>
                        <li>введение новых медицинских услуг.</li>
                    </ul>
                </Columns.Column>
            </Columns>
            <Columns className={classes.columns2}>
                <Columns.Column className={classes.columns1And2LeftColumn}>
                    <h3>Преимущества для онкодиспансеров:</h3>
                    <ul>
                        <li>снижение нагрузки на поликлинику за счет сокращения непрофильного приема;</li>
                        <li>выполнение приказов по срокам начала лечения и улучшение показателей выживаемости для МЗ;</li>
                        <li>выполнение приказов федерального МЗ и Указов президента в разрезе сроков до начала лечения.</li>
                    </ul>
                </Columns.Column>
                <Columns.Column>
                    <h3>Преимущества для ТФОМС:</h3>
                    <ul>
                        <li>введение новой услуги-повышения качеств оказания помощи;</li>
                        <li>снижение количества жалоб пациентов;</li>
                        <li>снижение количества запущенных стадий — экономия на лекарственном и лучевом лечении;</li>
                        <li>прямая экономия на стоимости ИЦХ.</li>
                    </ul>
                </Columns.Column>
            </Columns>

            <h2>Преимущества над существующими методиками</h2>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Показатель</th>
                        <th>Методики ИЦХ</th>
                        <th>Биочип ИЦХ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Точность</th>
                        <td>55%–95%</td>
                        <td>Не менее 93%<br /><em>Стандартизация всех реактивов</em></td>
                    </tr>
                    <tr>
                        <th>Стандартизация пробоподготовки</th>
                        <td>Нет</td>
                        <td>Да</td>
                    </tr>
                    <tr>
                        <th>Количество этапов пробоподготовки</th>
                        <td>Идентичное</td>
                        <td>Идентичное</td>
                    </tr>
                    <tr>
                        <th>Возможность использования под 1 пациента</th>
                        <td>Нет</td>
                        <td>Да</td>
                    </tr>
                    <tr>
                        <th>Количество одномоментно определяемых диагностических маркеров</th>
                        <td>1</td>
                        <td>До 3</td>
                    </tr>
                    <tr>
                        <th>Оплата через ОМС</th>
                        <td>Да</td>
                        <td>Да</td>
                    </tr>
                </tbody>
            </table>

            <div className={classes.image3AndSpecificationsCardContainer}>
                <FigureAlt className={classes.image3Container}>
                    <StaticImage
                        className={classes.image3}
                        src="img/biochip-3.jpg"
                        alt="Проведение иммуноцитохимического исследования"
                        layout="constrained"
                        width={696}
                        height={349}
                        objectFit="cover" />
                </FigureAlt>
                <ProductSpecificationsCard className={classes.specificationsCard}>
                    <ProductSpecificationsCard.Entry
                        label="Стадия"
                        value="В процессе регистрации" />
                    <ProductSpecificationsCard.Entry
                        label="Патент на изобретение"
                        value={
                            <ChevronLink
                                use="a"
                                href={data["patentFile"]["publicURL"]}
                                target="_blank">№ 197467</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        value={
                            <ChevronLink
                                use="a"
                                href={data["instructionsFile"]["publicURL"]}
                                target="_blank">Инструкция</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        label="Срок годности"
                        value="12 месяцев" />
                    <ProductSpecificationsCard.Entry
                        label="Упаковка"
                        value="1 штука" />
                    <ProductSpecificationsCard.Entry
                        label="Условия хранения"
                        value="Температура +4/+8 °С, отсутствие прямого солнечного света" />
                </ProductSpecificationsCard>
            </div>

            <h2>Тест-система для иммуноцитохимического исследования SER-1</h2>
            <Columns className={classes.columns3}>
                <Columns.Column className={classes.columns3LeftColumn}>
                    <h3>Диагностическая роль</h3>
                    <p>Настоящая тест-система может применяться в клинической практике для качественного выявления экспрессии онкомаркера EpCAM/BerEp4 в цитологических препаратах в качестве вспомогательного средства в дифференциальной диагностике опухолей эпителиальной и мезотелиальной природы в асцитической и плевральной жидкости.</p>
                </Columns.Column>
                <Columns.Column className={classes.columns3RightColumn}>
                    <h3>Исследуемый материал</h3>
                    <p>Клетки, полученные из выпотов в брюшную и плевральную полость.</p>
                </Columns.Column>
            </Columns>
            <h3>EpCAM/BerEp4</h3>
            <p>Экспрессируется на поверхности клеточной мембраны и в цитоплазме практически всех эпителиальных клеток, за исключением самых плоских эпителиев, гепатоцитов, почечных проксимальных трубчатых клеток, желудочных париетальных клеток и миоэпителиальных клеток. Однако фокальную положительность можно наблюдать в базальном слое плоскоклеточного эпителия энтодермы (например, небных миндалин) и мезодермы (например, шейки матки). Нормальные мезотелиальные клетки отрицательны к EpCAM, но могут давать положительную реакцию в случае выраженных реактивных изменений.</p>

            <h3>Схема интерпретации результатов тест-системы SER-1</h3>
            <div className={classes.diagram}>
                <h3>EpCam clone BerEP4</h3>
                <ul>
                    <li>
                        <span>Наличие в образце<br /> эпителиальных клеток</span>
                        <ul>
                            <li><span>Доброкачественные</span></li>
                            <li><span>Злокачественные</span></li>
                        </ul>
                    </li>
                    <li>
                        <span>Реактивные изменения<br /> в клетках мезотелия</span>
                        <ul>
                            <li><span>Доброкачественные</span></li>
                            <li><span>Злокачественные</span></li>
                        </ul>
                    </li>
                </ul>
            </div>

        </ProductPageContainer>
    );

}

export const pageQuery = graphql`
    query {
        patentFile: file(relativePath: {eq: "patent-biochip-enerdzh.pdf"}) {
            publicURL
        }
        instructionsFile: file(relativePath: {eq: "instruktsiia-test-sistema-ser-1.pdf"}) {
            publicURL
        }
    }  
`;
